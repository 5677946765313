@charset "UTF-8";
/*생방송*/
.onair_player {
  width: 352px;
}

.onair_player_top {
  background-image: url(pathImages/jplayer/bg/bg_onair_top.jpg);
  background-position: 0 0;
  background-repeat: no-repeat;
  position: relative;
  height: 37px;
}

.onair_player_top h2 {
  color: #fff;
  padding: 0;
  margin: 0;
  font-size: 12px;
}

.onair_player_top .fmam {
  color: #fff;
  position: absolute;
  top: 15px;
  right: 15px;
}

.onair_player_bottom {
  overflow: hidden;
  background-image: url(pathImages/jplayer/bg/bg_onair_bottom.jpg);
  background-position: 0 0;
  background-repeat: repeat-x;
  height: 49px;
}

.img_program {
  position: relative;
}

.img_program .p_main_logo {
  position: absolute;
  bottom: 0;
  left: 0;
}

.img_program .program_title {
  position: absolute;
  top: 60px;
  left: 185px;
  color: #fff;
}

.img_program .program_title.r {
  left: 30px;
}

.img_program .program_title p {
  padding-bottom: 30px;
  line-height: 1.8em;
}

.img_program .program_title p strong {
  font-size: 14px;
}

.onair_player_bottom ul {
  margin: 13px auto;
  width: 275px;
}

.onair_player_bottom li {
  float: left;
  padding: 0 2px;
}

.player_control .vod {
  height: 380px;
}

/*다시듣기*/
.player_top {
  background-color: #1c1b20;
  position: relative;
  padding: 15px;
}

.player_top h2 {
  color: #fff;
  padding: 0;
  margin: 0;
  font-size: 12px;
}

.player_top .pro_tite {
  color: #707072;
  position: absolute;
  top: 15px;
  left: 80px;
}

.player_top .home_btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: #333237;
  font-size: 11px;
}

.player_top .home_btn a {
  padding: 5px;
  color: #707072;
  text-decoration: none;
  line-height: 18px;
}

/*플레이어 컨트롤바 */
.jplayer-zone {
  width: 100%;
  height: 100%;
}

.player_control video {
  height: 100% !important;
}

.player_control {
  width: 100%;
  height: 100%;
  border-top: 1px solid #303038;
  border-right: 1px solid #303038;
  border-left: 1px solid #303038;
  background-color: #000;
  overflow: hidden;
}

.player_control .control_bar {
  background-image: url(pathImages/jplayer/bg/bg_player.jpg);
  background-position: 0 0;
  background-repeat: repeat-x;
  background-size: auto 100%;
  position: relative;
  display: flex;
  align-items: center;
}
.player_control .control_bar a {
  font-size: 0;
}

.player_control .control_bar .time {
  position: absolute;
  left: 90px;
  top: 15px;
  color: #bebebe;
}

.player_control .control_bar .speaker {
  position: absolute;
  top: 15px;
  right: 15px;
}

.player_control .control_bar .speaker li {
  float: left;
  margin-left: 5px;
  position: relative;
}

.player_control .control_bar .speaker .bg_speaker {
  width: 63px;
  background-image: url(pathImages/jplayer/bg/bg_speaker_bar.png);
  background-position: 0 5px;
  background-repeat: no-repeat;
  height: 15px;
}

.player_control .control_bar .speaker .bg_speaker .spearker_btn {
  position: absolute;
  top: -1px;
  right: -5px;
  z-index: 111;
  width: 11px;
  height: 9px;
  background-image: url(pathImages/jplayer/btn/btn01_player.png);
}

.player_control .control_bar .speaker .bg_speaker .spearker_bg {
  position: absolute;
  top: 5px;
  left: 0;
  z-index: 110;
  width: 30px;
  height: 4px;
  background-image: url(pathImages/jplayer/bg/bg_speaker_bar1.png);
  background-position: 0 0;
  background-repeat: no-repeat;
}

.player_control .time_bar {
  background-image: url(pathImages/jplayer/bg/bg01_player.jpg);
  background-position: 0 0;
  background-repeat: repeat-x;
  height: 5px;
  position: relative;
  overflow: hidden;
}

.player_control .time_bar .time_seek {
  width: 100%;
  height: 100%;
  background-image: url(pathImages/jplayer/bg/bg01_player_seek.jpg);
  background-position: 0 0;
  background-repeat: repeat-x;
}

.player_control .time_bar .time_line {
  position: relative;
  background-image: url(pathImages/jplayer/bg/bg02_player.jpg);
  background-position: 0 0;
  background-repeat: repeat-x;
  width: 50px;
  height: 100%;
}

.player_control .time_bar .time_btn {
  position: absolute;
  top: -1;
  right: -12px;
  width: 24px;
  height: 5px;
  background-image: url(pathImages/jplayer/btn/btn_player.png);
}

.player_control .control_bar .full_size {
  position: absolute;
  top: 0;
  right: 0;
}

/*설교 */
.player_control .control_bar .time_s {
  position: absolute;
  right: 60px;
  color: #bebebe;
}

.player_control .control_bar .speaker_s {
  display: inline-flex;
  align-items: center;
}

.player_control .control_bar .speaker_s li {
  float: left;
  margin-left: 5px;
  position: relative;
}

.player_control .control_bar .speaker_s .bg_speaker {
  width: 63px;
  background-image: url(pathImages/jplayer/bg/bg_speaker_bar.png);
  background-position: 0 5px;
  background-repeat: no-repeat;
  height: 15px;
}

.player_control .control_bar .speaker_s .bg_speaker .spearker_btn {
  position: absolute;
  top: -1px;
  right: -5px;
  z-index: 111;
  width: 11px;
  height: 9px;
  background-image: url(pathImages/jplayer/btn/btn01_player.png);
}

.player_control .control_bar .speaker_s .bg_speaker .spearker_bg {
  position: absolute;
  top: 5px;
  left: 0;
  z-index: 110;
  width: 30px;
  height: 4px;
  background-image: url(pathImages/jplayer/bg/bg_speaker_bar1.png);
  background-position: 0 0;
  background-repeat: no-repeat;
}

.sermon_player_bottom {
  overflow: hidden;
  background-image: url(pathImages/jplayer/bg/bg_onair_bottom.jpg);
  background-position: 0 0;
  background-repeat: repeat-x;
  padding: 15px;
  color: #fff;
}

.sermon_player_bottom a {
  color: #ffffff;
}

.sermonplayer_top {
  background-image: url(pathImages/jplayer/bg/bg01_sermontop.jpg);
  background-position: 0 0;
  background-repeat: repeat-x;
  position: relative;
  padding: 17px 15px;
  text-align: center;
  color: #fff;
  height: 20px;
  margin: 0;
}

.sermonplayer_top span {
  font-size: 14px;
}

.sermonplayer_top h2 {
  position: absolute;
  top: 10px;
  left: 10px;
}

.sermonplayer_top1 {
  background-image: url(pathImages/jplayer/bg/bg02_sermontop.jpg);
  background-position: 0 0;
  background-repeat: repeat-x;
  position: relative;
  padding: 12px 15px 9px 15px;
  text-align: center;
  color: #a9a9aa;
  line-height: 18px;
  margin: 0;
}

.sermonplayer_top1 .home_btn {
  position: absolute;
  top: 12px;
  right: 15px;
}

/*영상 */
.sermonplayer_top .vod02_btn {
  position: absolute;
  top: 20px;
  right: 0;
}

.vod_padding {
  padding: 0 10px 10px 10px;
  width: 640px;
  float: left;
  position: relative;
  border-right: 1px solid #292929;
}

.vod_padding .vodclose_btn {
  position: absolute;
  top: 200px;
  right: 0;
}

.vod_bg {
  background-color: #1f2023;
}

.pro_w {
  overflow: hidden;
  margin-top: 10px;
  height: 120px;
}

.pro_w h2 {
  color: #fff;
  line-height: 20px;
  padding-bottom: 5px;
}

.pro_w h2 span {
  color: #737373;
  font-size: 11px;
  font-weight: normal;
}

.pro_w .pro_left {
  float: left;
  width: 425px;
}

.pro_w .pro_left .pro_info {
  padding: 0 0 0 180px;
  position: relative;
}

.pro_w .pro_left .pro_info img {
  position: absolute;
  top: 0;
  left: 0;
}

.pro_w .pro_left .pro_info .pro_txt {
  padding: 10px;
  border: 1px solid #1f2023;
  background-color: #131313;
  color: #737373;
  height: 75px;
  overflow-y: auto;
}

.pro_w .pro_right {
  float: right;
  width: 195px;
}

.pro_w .pro_right ul {
  padding: 10px;
  border: 1px solid #1f2023;
  background-color: #131313;
  color: #737373;
}

.pro_w .pro_right li {
  line-height: 20px;
  background-image: url(pathImages/jplayer/icon/ico_voddot.gif);
  background-position: 0 center;
  background-repeat: no-repeat;
  padding-left: 8px;
}

.pro_w .pro_right li a {
  color: #737373;
}

.vodplayer_list {
  float: left;
  border-left: 1px solid #191919;
  padding: 10px;
  width: 144px;
}

.vodplayer_list h2 {
  color: #fff;
  margin-top: 15px;
  margin-bottom: 10px;
}

.vodplayer_list li {
  padding-bottom: 11px;
}

.vodplayer_list li p {
  padding: 5px 0;
  text-align: center;
  color: #737373;
}

/*공통 */
.player_control .m0 {
  margin-top: 0;
  height: 0;
}

/*셀렉트*/
.select_title {
  color: #fff;
  position: absolute;
  top: 15px;
  left: 10px;
}

.select_box {
  padding: 10px 10px 10px 65px;
  width: 100px;
}

.select {
  position: relative;
  line-height: normal;
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  background: #0f0f11;
  *zoom: 1;
}

.select * {
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-size: 11px;
}

.select .myValue {
  position: relative;
  z-index: 2;
  left: 0;
  top: 0;
  border: 1px solid #010101;
  color: #ff9c00;
  line-height: 19px;
  _line-height: normal;
  text-align: left;
  overflow: visible;
  background: transparent;
}

.select.open .myValue,
.select .myValue.outLine {
  border: 1px solid #999;
}

.select button.myValue {
  height: 21px;
  width: 100%;
  text-indent: 5px;
  *text-indent: 0;
  *padding-left: 5px;
}

.select div.myValue {
  height: 19px;
  text-indent: 8px;
}

.select .ctrl {
  position: absolute;
  top: 0;
  right: 0;
  width: 18px;
  height: 19px;
}

.select .arrow {
  position: absolute;
  width: 0;
  height: 0;
  top: 9px;
  right: 6px;
  line-height: 0;
  font-size: 0;
  border-top: 3px solid #fff;
  border-left: 3px solid #474747;
  border-right: 3px solid #474747;
}

.select ul {
  top: 20px;
  left: 0;
  width: 100%;
  list-style: none;
  border: 1px solid #010101;
  background: #0f0f12;
  overflow: hidden;
  z-index: 11111;
}

.select ul.aList {
  display: none;
}

.select.open ul.aList {
  display: block;
}

.select ul.iList {
  left: -2000%;
}

.select.open ul.iList {
  left: 0;
}

.select li {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  height: 18px;
  display: block;
  width: 100%;
}

.select li input.option {
  position: absolute;
  width: 100%;
  height: 20px;
  line-height: 20px;
}

.select li label {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  text-indent: 8px;
  *text-indent: 6px;
  height: 18px;
  line-height: 18px;
  color: #767676;
}

.select li a {
  display: block;
  text-indent: 8px;
  *text-indent: 6px;
  height: 18px;
  line-height: 18px;
  color: #767676;
  text-decoration: none;
}

.select li.hover * {
  color: #ff5641;
}

/*찬양*/
.praise_w {
  width: 352px;
}

.praise_w .praise_top {
  background-image: url(pathImages/jplayer/bg/bg_toppraise.gif);
  background-position: 0 0;
  background-repeat: repeat-x;
  height: 39px;
  position: relative;
}

.praise_w .praise_top .search {
  position: absolute;
  right: 10px;
  top: 10px;
}

.praise_w .praise_top .search input {
  border: 1px solid #020203;
  background-color: #96979a;
  width: 200px;
  color: #eee;
}

.praise_w .music_info {
  padding: 15px 15px 15px 15px;
  background-color: #ebebeb;
  border-bottom: 1px solid #c8c8c8;
  color: #555;
}

.praise_w .music_info .time {
  text-align: right;
  padding-top: 5px;
}

.praise_w .music_info .time_line {
  position: relative;
  background-image: url(pathImages/jplayer/bg/bg_praise_time.gif);
  background-position: 0 0;
  background-repeat: no-repeat;
  height: 11px;
}

.praise_w .music_info .time_line .jp-seek-bar {
  height: 100%;
}

.praise_w .music_info .time_line .btn_p {
  position: absolute;
  top: -1px;
  right: -10px;
  z-index: 112;
  width: 21px;
  height: 10px;
  background-image: url(pathImages/jplayer/btn/btn_praiseplayer.png);
}

.praise_w .music_info .time_line .line_red {
  position: absolute;
  background-color: #ff0012;
  height: 6px;
  width: 60px;
  z-index: 12;
  top: 2px;
  left: 5px;
}

.praise_w .music_info .album {
  position: relative;
  padding-left: 110px;
  height: 110px;
}

.praise_w .music_info .album .a_img {
  position: absolute;
  top: 0;
  left: 0;
}

.praise_w .music_info .album .a_t {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 5px;
}

.praise_w .music_info .album p {
  line-height: 20px;
}

.praise_w .music_info .album ul {
  padding-top: 8px;
}

.praise_w .music_info .album li {
  float: left;
}

.praise_w .music_info .album .volume_box {
  background-image: url(pathImages/jplayer/bg/bg_praise_volume.gif);
  background-position: 0 0;
  background-repeat: no-repeat;
  position: relative;
  width: 47px;
  height: 10px;
  margin-top: 10px;
}

.praise_w .music_info .album .volume_box .btn_p {
  position: absolute;
  top: -1px;
  right: -6px;
  z-index: 112;
  width: 12px;
  height: 9px;
  background-image: url(pathImages/jplayer/btn/btn_praise_volume.png);
}

.praise_w .music_info .album .volume_box .line_red {
  position: absolute;
  background-color: #ff0012;
  height: 5px;
  width: 15px;
  z-index: 12;
  top: 2px;
  left: 5px;
}

.praise_w .music_list {
  height: 280px;
  padding: 15px;
  overflow-y: scroll;
  color: #555;
  border-bottom: 1px solid #c8c8c8;
}

.praise_w .music_list a {
  color: #555;
}

.praise_w .music_list li {
  padding: 5px 0;
  font-size: 11px;
  letter-spacing: -1px;
}

.praise_w .music_list .jp-playlist-item {
  font-size: 12px;
  font-weight: bold;
}

.praise_w .music_list .jp-playlist-item .jp-artist {
  font-size: 11px;
  font-weight: normal;
}

.praise_w .bottom {
  border-top: 1px solid #fff;
  background-color: #f2f2f2;
  padding: 10px 15px;
  position: relative;
}

.praise_w .bottom .time_txt {
  position: absolute;
  top: 12px;
  right: 15px;
}

.jp-video-full {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.slider {
  -webkit-appearance: none;
  width: 80px;
  height: 4px;
  margin-top: 6px;
  border-radius: 5px;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #e62020;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #e62020;
  cursor: pointer;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .slider {
    -webkit-appearance: none;
    width: 80px;
    height: auto;
    margin-top: -21px;
    border-radius: 5px;
    outline: none;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
  }
}
input[type=range]::-ms-track {
  /*example */
  width: 250px;
  height: 10px;
  background: transparent;
  border-color: transparent;
  border-width: 6px 0;
  color: transparent;
}

input[type=range]::-ms-fill-upper {
  background: gray;
  border-radius: 6px;
}

input[type=range]::-ms-fill-lower {
  background: gray;
  border-radius: 6px;
}